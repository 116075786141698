import React from 'react'
import { Link } from 'gatsby'

const TopNav = ({ data }) => {
  return (
    <div className="text-sm text-gray-700">
      <Link to="/" className="mx-3 hover:underline">
        home
      </Link>
      |
      <Link to="/category/tech" className="mx-3 hover:underline">
        tech
      </Link>
      |
      <Link to="/category/life" className="mx-3 hover:underline">
        life
      </Link>
      |
      <Link to="/category/idea" className="mx-3 hover:underline">
        idea
      </Link>
      |
      <Link to="/works" className="mx-3 hover:underline">
        works
      </Link>
    </div>
  )
}

export default TopNav
