import { Link } from 'gatsby'
import React from 'react'
import TopNav from './topNav'

const Header = ({ siteMetadata }) => (
  <>
    <header className="bg-basic fixed w-full z-40 top-0 border-b border-gray-300 px-4 md:px-0">
      <nav className="flex justify-between max-w-2xl mx-auto h-12">
        <Link to="/" className="flex items-center h-12 text-xl font-bold pt-1">
          <img className="h-6" src="/title.svg"></img>
        </Link>
        <div className="hidden md:flex h-12 pt-1 justify-center items-center">
          <TopNav />
        </div>
      </nav>
    </header>
  </>
)

export default Header
