import { Link } from 'gatsby'
import React from 'react'

const Footer = () => (
  <>
    <footer className="w-full mt-12 px-4 md:px-0 text-xs">
      <nav className="flex justify-center max-w-2xl mx-auto my-6 space-x-4">
        <Link to="/profile" className="hover:underline">
          About
        </Link>
        <Link to="/privacy-policy" className="hover:underline">
          Privacy Policy
        </Link>
        <span>©2022 seuyubin.net</span>
      </nav>
    </footer>
  </>
)

export default Footer
